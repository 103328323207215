<template>
  <CollapsibleRoot v-show="hasAnyVisibleItems" v-model:open="isOpen" as-child>
    <li>
      <CollapsibleTrigger class="group flex w-full items-center justify-between py-3 text-button-xl text-text-strong data-[state=closed]:text-text md:hidden">
        <span class="flex items-center">
          <HallIcon
            class="text-icon-active"
            :class="{
              '-m-3 -mr-1 size-12': props.id,
              'mr-2 size-6': !props.id,
            }"
            :name="computedIcon"
          />
          {{ props.name }}
        </span>
        <Icon class="size-6 text-text transition-transform ease-in-out group-data-[state=closed]:rotate-180" name="chevron-up" />
      </CollapsibleTrigger>

      <CollapsibleContent class="overflow-hidden data-[state=closed]:animate-collapsible-close data-[state=open]:animate-collapsible-open md:hidden">
        <ul class="relative mb-3 rounded-lg bg-background-light-strong px-6">
          <li v-if="matchesSearchTerm(allLabel)" class="mb-3 border-b border-border-medium pb-4 text-base text-text-strong first:pt-5 last:border-b-0">
            <NuxtLink v-if="props.url" :to="props.url" class="hover:underline"><span v-html="highlightText(allLabel)" /></NuxtLink>
            <span v-else v-html="highlightText(allLabel)" />
            <Icon class="ml-2 inline-block size-4" name="arrow-right" />
          </li>
          <template v-for="(childItem, childIndex) in getVisibleItems(props.children)" :key="childIndex">
            <li class="py-2 text-base first:pt-5 last:pb-5">
              <NuxtLink v-if="childItem.url" :to="childItem.url" class="hover:underline"><span v-html="highlightText(childItem.name)" /></NuxtLink>
              <span v-else v-html="highlightText(childItem.name)" />
            </li>
          </template>
        </ul>
      </CollapsibleContent>

      <div class="hidden md:block">
        <ul class="relative mb-3 rounded-lg px-6 pl-8">
          <li
            class="relative pb-2 text-base text-text"
            :class="{
              'text-link-active': !matchesSearchTerm(allLabel),
            }"
          >
            <HallIcon
              class="absolute -left-8 -top-1 text-icon-active"
              :class="{
                '-m-3 size-12': props.id,
                'size-6': !props.id,
              }"
              :name="computedIcon"
            />
            <NuxtLink
              v-if="props.url"
              :to="props.url"
              class="inline-block hover:underline"
              :class="{
                'font-medium': props.searchTerm.length < 3,
              }"
            >
              <span v-html="highlightText(props.name)" />
            </NuxtLink>
          </li>
          <template v-for="(childItem, childIndex) in getVisibleItems(props.children)" :key="childIndex">
            <li class="py-1 text-base first:pt-5 last:pb-5">
              <NuxtLink v-if="childItem.url" :to="childItem.url" class="hover:underline"><span v-html="highlightText(childItem.name)" /></NuxtLink>
              <span v-else v-html="highlightText(childItem.name)" />
            </li>
          </template>
        </ul>
      </div>
    </li>
  </CollapsibleRoot>
</template>

<script setup lang="ts">
import { useTranslate } from '~/composables/translate'
import { CollapsibleRoot, CollapsibleTrigger, CollapsibleContent } from 'radix-vue'
import HallIcon from '~/components/icon/HallIcon.vue'

const isOpen = ref(false)

interface ChildCategory {
  id: number
  name: string
  url: string
  children: ChildCategory[] | null
  adopted: boolean
}

const props = withDefaults(
  defineProps<{
    accordionId?: string
    icon?: string
    id?: number | undefined
    name?: string
    allName?: string
    url?: string
    children?: ChildCategory[]
    searchTerm?: string
  }>(),
  {
    accordionId: '',
    icon: '',
    id: undefined,
    name: '',
    allName: '',
    url: '',
    children: () => [],
    searchTerm: '',
  }
)

const { t } = useTranslate()

const allLabel = computed(() => props.allName || t('w.module.categories_search.view_all').replace('%s', props.name.toLowerCase()))
const computedIcon = computed(() => props.icon || 'legacy-' + props.id)

const escapeRegExp = (string: string) => {
  return string.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\u002d')
}

const highlightText = (text: string) => {
  if (props.searchTerm?.length < 3) {
    return text
  }

  const ignoreCaseForSearchTerm = new RegExp(escapeRegExp(props.searchTerm), 'gi')

  return text.replace(ignoreCaseForSearchTerm, '<strong>$&</strong>')
}

const matchesSearchTerm = (text: string) => {
  if (props.searchTerm?.length < 3) {
    return true
  }

  const ignoreCaseForSearchTerm = new RegExp(escapeRegExp(props.searchTerm), 'gi')

  return text.match(ignoreCaseForSearchTerm)
}

const getVisibleItems = (items: ChildCategory[]) => {
  return items.filter((item) => matchesSearchTerm(item.name))
}

const hasAnyVisibleItems = computed(() => getVisibleItems(props.children)?.length > 0 || matchesSearchTerm(allLabel.value))

const closeAccordion = () => {
  isOpen.value = false
}

const openAccordion = () => {
  isOpen.value = true
}

const hasVisibleItems = () => {
  return hasAnyVisibleItems.value
}

defineExpose({
  closeAccordion,
  openAccordion,
  hasVisibleItems,
})
</script>
