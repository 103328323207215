<template>
  <div class="bg-background-dark pb-16 pt-12">
    <div class="h-container flex flex-col">
      <h2 v-if="title" class="mb-4 text-center text-h2">{{ title }}</h2>
      <div class="-mx-5 flex overflow-auto px-5 sm:-mx-8 sm:px-8 md:mx-0 md:px-0">
        <ul class="mx-auto whitespace-nowrap">
          <li v-for="(item, index) of items" :key="index" class="inline-block">
            <button
              v-if="item.items.length"
              type="button"
              class="relative inline-block px-6 pb-3 pt-4 after:absolute after:inset-0 after:border-b after:border-border-hover after:transition-all after:hover:border-border-medium-hover"
              :class="currentTab === index && 'after:border-b-2 after:border-border-medium-active'"
              @click="currentTab = index"
            >
              {{ item.name }}
            </button>
          </li>
        </ul>
      </div>
      <div>
        <PremiumProductsSlider v-for="(item, index) of items" v-show="currentTab === index" :key="index" :items="item.items" />
      </div>
      <Button v-if="button" :link="button.url" icon-name="arrow-right" class="btn-black btn-rounded mx-auto mt-4 py-2.5">{{ button.text }}</Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import PremiumProductsSlider from '~/components/premium/PremiumProductsSlider.vue'
import Button from '~/components/button/Button.vue'

interface PremiumSection {
  title: string
  button: {
    text: string
    url: string
  }
  items: {
    name: string
    items: {
      title: string
      price: number
      discountPrice: number | null
      discountPercent: number
      url: string
      image: {
        src: string
        alt: string
      }
    }[]
  }[]
}

const props = defineProps<{ data: PremiumSection }>()

const currentTab = ref(0)

const title = computed(() => props.data.title)
const button = computed(() => props.data.button)
const items = computed(() => props.data.items)
</script>
