<template>
  <BackgroundImage />
  <HeroIndex v-if="data.heroIndex" :data="data.heroIndex" />
  <InfoBanner class="h-container mt-5" :text="t('w.module.info_banner.title')" :url="t('w.module.info_banner.url')" />
  <RoomCategories v-if="data.roomCategories" class="mt-10" :data="data.roomCategories" />
  <ValuePropositions v-if="data.valuePropositions" class="mt-12" :data="data.valuePropositions" />
  <SpecialOffers v-if="data.specialOffers" class="mt-12" :data="data.specialOffers" />
  <Premium v-if="data.premium" class="mt-12" :data="data.premium" />
  <InstagramGrid v-if="data.instagramGridItems" class="mt-12" :data="data.instagramGridItems" />
  <ContentModule v-if="data.bottomText" class="mt-12">
    <div class="text" v-html="data.bottomText" />
  </ContentModule>
  <CategoriesSearch v-if="categoryItems" :items="categoryItems" class="mt-12" />
  <BlogGrid v-if="data.blogGridItems" class="mt-12" :data="data.blogGridItems" />
  <CtaBanner class="mt-8" />
  <RtbHomePage />
  <LastViewedProductsSection class="h-container flex flex-col items-center py-10" />
</template>

<script setup lang="ts">
import RtbHomePage from '~/components/marketing/rtbhouse/rtb-home-page.vue'
import BlogGrid from '~/components/blog-grid/BlogGrid.vue'
import ContentModule from '~/components/content-module/ContentModule.vue'
import CategoriesSearch from '~/components/categories-search/CategoriesSearch.vue'
import InfoBanner from '~/components/info-banner/InfoBanner.vue'
import RoomCategories from '~/components/room-categories/RoomCategories.vue'
import ValuePropositions from '~/components/value-propositions/ValuePropositions.vue'
import SpecialOffers from '~/components/special-offers/SpecialOffers.vue'
import InstagramGrid from '~/components/instagram-grid/InstagramGrid.vue'
import CtaBanner from '~/components/cta-banner/CtaBanner.vue'
import HeroIndex from '~/components/hero-index/HeroIndex.vue'
import BackgroundImage from '~/components/background-image/BackgroundImage.vue'
import Premium from '~/components/premium/Premium.vue'

import { useTranslationUrlStore } from '~/stores/translation-url'
import { useTranslate } from '~/composables/translate'
import LastViewedProductsSection from '~/components/last-viewed-products-section/LastViewedProductsSection.vue'

const {
  public: { countryId },
} = useRuntimeConfig()

const { t } = useTranslate()

const user = useUserState()

const route = useRoute()

const { data } = await useApiData('home', usePath())

const translationUrlStore = useTranslationUrlStore()
translationUrlStore.setTranslationUrl(route.fullPath, data.value.translationUrl)

const categoryItems = computed(() => {
  return data.value.categories
})

useHead({
  title: computed(() => data.value.pageTitle),
  meta: [{ name: 'description', content: computed(() => data.value.metaDescription) }],
})

const { home } = useCriteo()
const criteoHomeEvent = () => {
  if (countryId !== 2) {
    return
  }

  const email = user.value ? user.value.emailMd5 : null

  home(email)
}

criteoHomeEvent()
</script>
