<template>
  <div class="relative -mx-2 max-w-screen-xl px-2 md:mx-auto md:px-0" :data-item-count="items.length">
    <div class="md:px-14">
      <section v-if="items.length >= 2" ref="emblaRef" class="carousel-list overflow-hidden">
        <div
          class="-ml-5 grid auto-cols-[calc(2/3*100%)] grid-flow-col sm:auto-cols-[40%] md:auto-cols-[calc(1/3*100%)] lg:auto-cols-[25%]"
          style="backface-visibility: hidden; touch-action: pan-y pinch-zoom"
        >
          <div v-for="(item, index) of props.items" :key="index" class="pl-5">
            <PremiumProductsCard class="my-8" v-bind="item" />
          </div>
        </div>

        <div class="pointer-events-none absolute inset-0 flex justify-between px-2">
          <div class="w-12 bg-gradient-to-r from-background-dark" />
          <div class="w-12 bg-gradient-to-l from-background-dark" />
        </div>

        <div class="pointer-events-none absolute inset-0 flex items-center justify-between">
          <div class="w-12">
            <button
              v-show="!isFirstSlide"
              class="pointer-events-auto size-12 rounded-full border border-border p-3.5 transition-colors hover:border-border-hover focus:border-border-hover active:border-border-active"
              type="button"
              :aria-label="t('global.previous')"
              @click="() => emblaApi?.scrollPrev()"
            >
              <Icon class="size-5" name="arrow-left" />
            </button>
          </div>
          <div class="w-12">
            <button
              v-show="!isLastSlide"
              class="pointer-events-auto size-12 rounded-full border border-border p-3.5 transition-colors hover:border-border-hover focus:border-border-hover active:border-border-active"
              type="button"
              :aria-label="t('global.next')"
              @click="() => emblaApi?.scrollNext()"
            >
              <Icon class="size-5" name="arrow-right" />
            </button>
          </div>
        </div>
      </section>

      <div v-if="items.length <= 4" class="static-list -ml-5 justify-center">
        <div v-for="(item, index) of props.items" :key="index" class="w-full pl-5 sm:basis-2/5 md:basis-1/3 lg:basis-1/4" aria-hidden="false">
          <PremiumProductsCard class="my-8" v-bind="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import emblaCarouselVue from 'embla-carousel-vue'
import { useTranslate } from '~/composables/translate'
import PremiumProductsCard from '~/components/premium/PremiumProductsCard.vue'

const props = withDefaults(
  defineProps<{
    items?: {
      title: string
      price: number
      discountPrice: number | null
      discountPercent: number
      url: string
      image: {
        src: string
        alt: string
      }
    }[]
  }>(),
  {
    items: () => [],
  }
)

const [emblaRef, emblaApi] = emblaCarouselVue({
  align: 'center',
  slidesToScroll: 1,
  breakpoints: {
    '(min-width: 680px)': {
      align: 'center',
      slidesToScroll: 2,
    },
    '(min-width: 1000px)': {
      align: 'start',
      slidesToScroll: 3,
    },
    '(min-width: 1200px)': {
      align: 'start',
      slidesToScroll: 4,
    },
  },
})

const isFirstSlide = ref(true)
const isLastSlide = ref(false)

onMounted(() => {
  const updateSelectedIndex = () => {
    isFirstSlide.value = !emblaApi.value?.canScrollPrev()
    isLastSlide.value = !emblaApi.value?.canScrollNext()
  }

  if (emblaApi.value) {
    emblaApi.value.on('reInit', updateSelectedIndex).on('select', updateSelectedIndex)
  }
})

const { t } = useTranslate()
</script>

<style lang="scss" scoped>
.static-list {
  @apply hidden;
}

.carousel-list {
  @apply block;
}

[data-item-count='0'] {
  .static-list {
    @apply hidden;
  }

  .carousel-list {
    @apply hidden;
  }
}

[data-item-count='1'] {
  .static-list {
    @apply flex;
  }

  .carousel-list {
    @apply hidden;
  }
}

@media (min-width: theme('screens.sm')) {
  [data-item-count='2'] {
    .static-list {
      @apply flex;
    }

    .carousel-list {
      @apply hidden;
    }
  }
}

@media (min-width: theme('screens.md')) {
  [data-item-count='3'] {
    .static-list {
      @apply flex;
    }

    .carousel-list {
      @apply hidden;
    }
  }
}

@media (min-width: theme('screens.lg')) {
  [data-item-count='4'] {
    .static-list {
      @apply flex;
    }

    .carousel-list {
      @apply hidden;
    }
  }
}
</style>
