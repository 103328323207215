<template>
  <div class="h-container">
    <h2 v-if="t('w.module.room_categories.title')" class="mb-10 mt-4 text-center md:mb-11 xl:mb-12">{{ t('w.module.room_categories.title') }}</h2>
    <div class="h-gap grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6">
      <NuxtLink
        v-for="(card, cardIndex) in props.data"
        :key="`room-card-${cardIndex}`"
        :to="card.url"
        class="h-translate-y-0 hover:h-translate-y-1 group text-center transition-transform duration-500"
      >
        <OnImg
          v-if="card.imageUrl"
          class="aspect-[4/3] h-auto w-full rounded-2xl object-cover transition-shadow group-hover:shadow-05"
          fit="cover"
          width="208"
          height="156"
          sizes="sm:300px lg:300px"
          :alt="card.title"
          :title="card.title"
          :src="card.imageUrl"
        />
        <span v-if="card.title" class="subheading mb-3.5 mt-3 block hyphens-auto group-hover:text-text-strong sm:mb-4 md:mb-5 xl:mb-4" v-html="card.title" />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import OnImg from '~/components/on-img/OnImg.vue'
const { t } = useTranslate()

interface RoomCategory {
  title: string
  url: string
  imageUrl: string
}

const props = defineProps<{ data: RoomCategory[] }>()
</script>
