<template>
  <div>
    <div ref="emblaRef" class="relative transform-gpu overflow-hidden sm:rounded-4xl">
      <div class="grid auto-cols-[100%] grid-flow-col" style="backface-visibility: hidden; touch-action: pan-y pinch-zoom">
        <div
          v-for="(item, index) in props.items"
          :key="index"
          class="relative flex size-full min-h-[22.5rem] min-w-0 shrink-0 basis-full flex-col"
          :class="{
            [PRIMARY_CLASS_NAME]: !item.secondary,
            'lg:hidden': item.secondary,
          }"
        >
          <OnImg
            class="inset-0 size-full object-cover sm:absolute"
            :src="item.image.src"
            :alt="item.image.alt"
            sizes="sm:300px md:600px lg:1000px"
            width="300"
            height="168"
            :loading="index > 0 ? 'lazy' : undefined"
            :decoding="index > 0 ? 'async' : undefined"
          />
          <component
            :is="item.button !== undefined ? NuxtLink : 'div'"
            :to="item.button?.url !== '#' ? item.button?.url : undefined"
            class="group relative flex h-full flex-col items-center px-4 py-5 text-center sm:items-start sm:px-10 sm:py-8 sm:text-left md:px-20 md:py-10"
            :tabindex="item.button !== undefined ? (selectedIndex === 0 ? '0' : '-1') : undefined"
            :class="{
              'lg:px-10 lg:py-8': props.size === 'large',
              'lg:p-8': props.size === 'small',
              'cursor-pointer': isItemClickable(item),
            }"
            @click="onClick(item)"
          >
            <component
              :is="props.headingElement"
              v-if="item.title"
              class="text-banner-title font-light sm:text-h1 md:text-banner-title"
              :class="{
                'lg:text-h1': props.size !== 'single',
                'sm:text-text-inverted': item.inverted,
              }"
            >
              <span v-html="item.title" />
            </component>

            <div
              v-if="item.content"
              class="mt-4 flex w-full text-left sm:text-subtitle sm:leading-[1.8]"
              :class="{
                'sm:text-text-inverted': item.inverted,
              }"
              v-html="item.content"
            />
            <!-- hide login button (url is '#') when user is logged in -->
            <div v-if="item.button && !(item.button?.url === '#' && !!user)" class="flex w-full justify-center sm:block sm:w-auto sm:justify-start">
              <span
                class="mt-4 cursor-pointer items-center rounded-full px-6 py-3 text-button-xl sm:mt-8 sm:flex"
                :class="{
                  'bg-background-black text-text-inverted hover:bg-background-black-hover group-active:bg-background-black-active': !item.inverted,
                  'bg-background-inverted text-text hover:bg-background-inverted-hover': item.inverted,
                }"
              >
                {{ item.button.text }}
                <Icon class="ml-0.5 inline-block size-5 py-1 sm:py-0" name="arrow-right" />
              </span>
            </div>
          </component>
        </div>
      </div>

      <div v-if="props.items.length > 1" class="pointer-events-none absolute inset-0 z-0 hidden justify-between sm:flex">
        <button
          class="pointer-events-auto bg-gradient-to-r opacity-30 transition-all hover:opacity-100 md:px-4"
          :class="{
            'text-icon-inverted hover:from-white/20': isNavigationInverted,
            'text-icon hover:from-background-black/10': !isNavigationInverted,
            'lg:px-0': props.size !== 'single',
          }"
          type="button"
          :aria-label="t('global.previous')"
          @click="() => emblaApi?.scrollPrev()"
        >
          <Icon class="size-10 rotate-90" name="chevron-down" />
        </button>
        <button
          class="pointer-events-auto bg-gradient-to-l opacity-30 transition-all hover:opacity-100 md:px-4"
          :class="{
            'text-icon-inverted hover:from-white/20': isNavigationInverted,
            'text-icon hover:from-background-black/10': !isNavigationInverted,
            'lg:px-0': props.size !== 'single',
          }"
          type="button"
          :aria-label="t('global.next')"
          @click="() => emblaApi?.scrollNext()"
        >
          <Icon class="size-10 rotate-90" name="chevron-up" />
        </button>
      </div>
    </div>

    <ol v-if="props.items.length > 1" class="flex justify-center sm:mt-6">
      <li v-for="(item, index) in props.items" :key="index" :class="{ 'lg:hidden': item.secondary }">
        <button
          class="relative block size-6 before:absolute before:inset-2 before:size-2 before:rounded-full before:bg-current before:transition-colors hover:text-border-active"
          :class="{
            'text-border': selectedIndex === index,
            'text-border-medium': selectedIndex !== index,
          }"
          type="button"
          :aria-label="`${t('global.goto_slide')} ${index}`"
          @click="() => emblaApi?.scrollTo(index)"
        />
      </li>
    </ol>
  </div>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'

import OnImg from '~/components/on-img/OnImg.vue'
import Icon from '~/components/icon/Icon.vue'

import { useTranslate } from '~/composables/translate'
import { useLoginModalStore } from '~/stores/login-modal'
import { useUserState } from '~/composables/states'

import emblaCarouselVue from 'embla-carousel-vue'
import Autoplay from 'embla-carousel-autoplay'

interface HeroIndexItem {
  title: string
  content: string
  button: {
    text: string
    url: string
  }
  image: {
    src: string
    alt: string
  }
  inverted: boolean
  secondary?: boolean
}

const props = withDefaults(
  defineProps<{
    size?: 'single' | 'large' | 'small'
    items?: HeroIndexItem[]
    headingElement?: string
  }>(),
  {
    size: 'single',
    items: () => [],
    headingElement: 'span',
  }
)

const PRIMARY_CLASS_NAME = 'image-carousel-primary'

const [emblaRef, emblaApi] =
  props.items.length > 1
    ? emblaCarouselVue(
        {
          loop: true,
          duration: 20,
          breakpoints: {
            '(min-width: 1200px)': {
              slides: `.${PRIMARY_CLASS_NAME}`,
            },
          },
        },
        [
          Autoplay({
            delay: 10000,
            stopOnInteraction: false,
            stopOnMouseEnter: true,
          }),
        ]
      )
    : [ref(undefined), ref(undefined)]

const selectedIndex = ref(0)

onMounted(() => {
  const updateSelectedIndex = () => (selectedIndex.value = emblaApi.value?.selectedScrollSnap() ?? 0)

  if (emblaApi.value) {
    emblaApi.value.on('reInit', updateSelectedIndex).on('select', updateSelectedIndex)
  }
})

const { t } = useTranslate()

const loginModalStore = useLoginModalStore()
const user = useUserState()

const isNavigationInverted = computed(() => props.items[selectedIndex.value]?.inverted ?? false)

const onClick = (item: HeroIndexItem) => {
  if (item.button?.url === '#' && !user.value) {
    loginModalStore.open(t('w.login.welcome'))
  }
}

const isItemClickable = (item: HeroIndexItem): boolean => {
  if (item.button?.url === '#' && !user.value) {
    return true
  }

  return item.button?.url !== '#'
}
</script>
