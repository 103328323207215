<template>
  <div class="h-container">
    <h2 v-if="t('w.module.special_offers.title')" class="mb-8 pt-4 text-center sm:mb-10 md:mb-12">{{ t('w.module.special_offers.title') }}</h2>
    <div class="h-gap grid grid-cols-1 sm:grid-cols-4 md:grid-cols-6">
      <NuxtLink
        v-for="(card, cardIndex) in props.data"
        :key="`card-${cardIndex}`"
        :to="card.action.url"
        class="h-translate-y-0 hover:h-translate-y-1 group relative block overflow-hidden rounded-2xl bg-background-bright px-8 pb-10 pt-6 transition-all duration-500 hover:shadow-06"
        :class="[cardIndex === 0 || cardIndex === 1 ? 'sm:col-span-4 sm:h-72 sm:pt-10 md:col-span-3' : 'sm:col-span-2']"
      >
        <OnImg
          v-if="card.image"
          :src="card.image"
          :alt="card.title"
          :title="card.title"
          class="absolute left-20 top-2/4 h-[236px] w-[473px] -translate-y-2/4 object-cover xs:left-20 md:left-20 xl:left-16"
          :class="[cardIndex === 0 || cardIndex === 1 ? 'sm:left-20 sm:h-[360px] sm:w-[720px] lg:left-24' : 'lg:left-20']"
          :sizes="cardIndex === 0 || cardIndex === 1 ? 'sm:300px lg:800px xl:1200px' : 'sm:300px lg:500px xl:800px'"
        />
        <div
          class="absolute left-0 top-0 h-full bg-gradient-to-r from-background-bright/30 from-50% to-75%"
          :class="[cardIndex === 0 || cardIndex === 1 ? 'w-[360px] sm:w-[560px]' : 'w-[360px]']"
        />
        <div class="relative z-10">
          <h3 class="mb-6" v-html="card.title" />
          <div class="btn btn-outline btn-rounded bg-background-bright group-hover:bg-black group-hover:text-white">
            <div class="flex items-center justify-center">
              {{ card.action.text }}
              <Icon name="arrow-right" class="size-5 pl-0.5" />
            </div>
          </div>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import OnImg from '~/components/on-img/OnImg.vue'
import Icon from '~/components/icon/Icon.vue'

const { t } = useTranslate()

interface SpecialOffer {
  title: string
  image: string
  action: {
    text: string
    url: string
  }
}

const props = defineProps<{ data: SpecialOffer[] }>()
</script>
