<template>
  <div class="h-container">
    <div class="md:border-b md:border-border md:pb-20">
      <h2 class="mb-8 text-center text-h2">{{ t('w.module.categories_search.title') }}</h2>
      <div class="relative mx-auto mb-6 flex max-w-[720px] md:mb-11">
        <input
          ref="searchInput"
          v-model="searchTerm"
          type="text"
          :placeholder="isBreakpointMd ? t('w.module.categories_search.placeholder') : t('w.module.categories_search.placeholder_short')"
          class="w-full rounded-3xl border border-border-medium py-3 pl-4 pr-12 text-lg outline-none transition-colors hover:border-border-medium-hover active:border-border-medium-hover"
          autocomplete="off"
          @keydown.esc="searchTerm = ''"
        />
        <Button v-show="searchTerm.length > 0" type="button" icon-name="close" class="btn-icon btn-rounded btn-interactive absolute right-2 top-1.5" @click="searchTerm = ''" />
      </div>
      <div class="flex flex-wrap md:hidden">
        <ul class="basis-full sm:max-w-1/2 sm:basis-1/2 sm:pr-4">
          <template v-for="(item, index) in props.items.slice(0, Math.floor(props.items.length / 2))" :key="`${index}`">
            <CategoriesSearchAccordion v-bind="item" ref="accordions" :accordion-id="`${props.id}-accordion-${index}`" :search-term="searchTerm" />
          </template>
        </ul>
        <ul class="basis-full sm:max-w-1/2 sm:basis-1/2 sm:border-l sm:border-border-medium sm:pl-4">
          <template v-for="(item, index) in props.items.slice(Math.floor(props.items.length / 2), props.items.length)" :key="`${index}`">
            <CategoriesSearchAccordion v-bind="item" ref="accordions" :accordion-id="`${props.id}-accordion-second-${index}`" :search-term="searchTerm" />
          </template>
        </ul>
      </div>
      <ul class="hidden flex-wrap md:visible md:flex">
        <template v-for="(item, index) in props.items" :key="`${index}`">
          <CategoriesSearchAccordion
            v-bind="item"
            ref="accordions"
            class="basis-1/3 lg:basis-1/4 xl:basis-1/5 2xl:basis-1/6"
            :accordion-id="`${props.id}-accordion-desktop-${index}`"
            :search-term="searchTerm"
          />
        </template>
      </ul>
      <div v-if="!hasVisibleAccordions" class="flex w-full flex-col items-center text-center text-lg">
        <Icon class="h-[45px] w-[46px] rounded-full bg-wishlist px-[25px] py-[26px] text-illustration-red" name="house-delivery" />
        <div class="mt-6 font-semibold">{{ t('w.module.categories_search.no_categories_title') }}</div>
        <div class="mt-2">{{ t('w.module.categories_search.no_categories_suggestion') }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMounted } from '@vueuse/core'
import { useTranslate } from '~/composables/translate'
import { useBreakpoint } from '~/composables/breakpoint'

import Button from '~/components/button/Button.vue'
import CategoriesSearchAccordion from '~/components/categories-search/CategoriesSearchAccordion.vue'

interface CategoryItem {
  id: number
  name: string
  url: string
  children: CategoryItemChild[]
  adopted: boolean
}

interface CategoryItemChild {
  id: number
  name: string
  url: string
  children: CategoryItemChild[] | null
  adopted: boolean
}

const props = withDefaults(
  defineProps<{
    id?: string
    items?: CategoryItem[]
  }>(),
  {
    id: 'categories-search',
    items: () => [],
  }
)

const { t } = useTranslate()
const { isBreakpoint } = useBreakpoint()
const isMounted = useMounted()
const isBreakpointMd = computed(() => isMounted.value && isBreakpoint('md').value)

const searchTerm = ref('')

const accordions = ref<InstanceType<typeof CategoriesSearchAccordion>[] | null>(null)

const hasVisibleAccordions = ref(true)

const isFilterActive = computed(() => {
  return searchTerm.value.length >= 3
})

watch(
  () => isFilterActive.value,
  () => {
    if (!isFilterActive.value) {
      if (accordions.value) {
        nextTick(() => {
          accordions.value.forEach((accordion) => {
            accordion.closeAccordion()
          })
        })
      }
    }
  }
)

watch(
  () => searchTerm.value,
  () => {
    if (searchTerm.value.length >= 3) {
      if (accordions.value) {
        nextTick(() => {
          hasVisibleAccordions.value = false

          accordions.value.forEach((accordion) => {
            accordion.openAccordion()

            nextTick(() => {
              hasVisibleAccordions.value = accordion.hasVisibleItems() || hasVisibleAccordions.value
            })
          })
        })
      }
    } else {
      hasVisibleAccordions.value = true
    }
  }
)
</script>
