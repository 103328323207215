<template>
  <div class="h-container">
    <div class="pb-10 pt-4 text-center md:pb-11 xl:pb-12">
      <h2 v-if="t('w.module.instagram_grid.title')">{{ t('w.module.instagram_grid.title') }}</h2>
      <div v-if="t('w.module.instagram_grid.description')" class="subheading text mt-6" v-html="t('w.module.instagram_grid.description')" />
      <Button v-if="t('w.module.instagram_grid.action.url')" :link="t('w.module.instagram_grid.action.url')" link-target="_blank" class="btn-outline btn-rounded mt-8">
        {{ t('w.module.instagram_grid.action.text') }}
      </Button>
    </div>
    <div class="h-gap instagram-grid-list grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 xl:grid-cols-5">
      <NuxtLink v-for="(item, itemIndex) in props.data" :key="`instagram-card-${itemIndex}`" :to="item.url" class="group relative">
        <OnImg :src="item.image.src" class="aspect-square h-auto w-full object-cover" :alt="item.image.alt" sizes="sm:200px lg:400px" />
        <div ref="instagramCardContent" class="absolute inset-0 bg-background p-4 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
          <p class="instagram-grid-text overflow-hidden text-ellipsis text-base" :style="`-webkit-line-clamp: ${newLineCount}`" v-html="item.title" />
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import OnImg from '~/components/on-img/OnImg.vue'

const { t } = useTranslate()

interface InstagramGridItem {
  title: string
  url: string
  image: {
    src: string
    alt: string
  }
}

const props = defineProps<{ data: InstagramGridItem[] }>()

const instagramCardContent = ref<HTMLDivElement[] | null>(null)
const newLineCount = ref(0)

const updateLineCount = () => {
  if (instagramCardContent.value === null || instagramCardContent.value.length > 0) {
    return
  }

  const totalHeight = instagramCardContent.value[0].offsetHeight
  newLineCount.value = Math.floor((totalHeight - 32) / 18)
}

const lineCountListenerFunction = useThrottleFrame(updateLineCount)

onMounted(() => {
  window.addEventListener('resize', lineCountListenerFunction)
  updateLineCount()
})

onUnmounted(() => {
  window.removeEventListener('resize', lineCountListenerFunction)
})
</script>

<style lang="scss">
.instagram-grid-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;

  @media (min-width: theme('screens.md')) {
    -webkit-line-clamp: 10;
  }

  @media (min-width: theme('screens.lg')) {
    -webkit-line-clamp: 11;
  }
}

.instagram-grid-list {
  @apply [&>*:nth-child(10)]:hidden xs:[&>*:nth-child(10)]:hidden
        sm:[&>*:nth-child(10)]:hidden lg:[&>*:nth-child(10)]:hidden xl:[&>*:nth-child(10)]:block
        xl:[&>*:nth-child(11)]:hidden lg:[&>*:nth-child(8)]:block
        [&>*:nth-child(9)]:hidden xs:[&>*:nth-child(9)]:block sm:[&>*:nth-child(9)]:hidden xl:[&>*:nth-child(9)]:block;
}
</style>
