<!--
  Home Page code

  This code should be pasted into the home page of your website.

  <iframe
    src="https://creativecdn.com/tags?id=pr_TeiDTPzcDfIO1kOXQ8lW_home"
    width="1" height="1" scrolling="no" frameBorder="0" style="display: none;"></iframe>
-->
<template>
  <iframe v-if="marketingCookiesAccepted" ref="iframe" width="1" height="1" style="display: none" />
</template>

<script setup lang="ts">
const { marketingCookiesAccepted } = useCookieConsent()
const { t } = useTranslate()
const iframe = ref<HTMLIFrameElement | null>(null)
const iframeSrc = computed(() => `https://creativecdn.com/tags?id=pr_${t('RTB_hash')}_home`)
watch(
  () => iframeSrc.value,
  () => iframe.value?.contentWindow?.location.replace(iframeSrc.value)
)
onMounted(() => {
  if (iframe.value) {
    iframe.value.src = iframeSrc.value
  }
})
</script>
