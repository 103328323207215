<template>
  <div class="sm:h-container mt-4 w-full md:mt-8">
    <h1 v-if="firstElementTitle" class="sr-only">{{ firstElementTitle }}</h1>
    <div v-if="hasPrimaryItems && hasSecondaryItems" class="h-gap grid grid-cols-1 lg:grid-cols-12">
      <ImageCarousel class="col-span-1 lg:col-span-7" :items="combinedItems" size="large" heading-element="h2" />
      <ImageCarousel class="hidden lg:col-span-5 lg:block" :items="data.secondaryItems ?? undefined" size="small" heading-element="h2" />
    </div>
    <div v-else-if="hasPrimaryItems">
      <ImageCarousel :items="data.primaryItems ?? undefined" size="single" heading-element="h2" />
    </div>
  </div>
</template>

<script setup lang="ts">
import ImageCarousel from '~/components/image-carousel/ImageCarousel.vue'

interface HeroIndexItem {
  title: string
  content: string
  button: {
    text: string
    url: string
  }
  image: {
    src: string
    alt: string
  }
  inverted: boolean
}

interface HeroIndex {
  primaryItems: HeroIndexItem[] | null
  secondaryItems: HeroIndexItem[] | null
}

const props = defineProps<{ data: HeroIndex }>()

const firstElementTitle = computed(() => props.data.primaryItems?.[0].title)
const hasPrimaryItems = computed(() => !!props.data.primaryItems?.length)
const hasSecondaryItems = computed(() => !!props.data.secondaryItems?.length)

const combinedItems = computed(() => {
  return [...(props.data.primaryItems ?? []).map((item) => ({ ...item, secondary: false })), ...(props.data.secondaryItems ?? []).map((item) => ({ ...item, secondary: true }))]
})
</script>
