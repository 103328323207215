<template>
  <NuxtLink :to="props.url" class="h-translate-y-0 hover:h-translate-y-1 group inline-block w-full overflow-hidden rounded-t-[180px] transition-all hover:shadow-premium-card">
    <span class="block bg-background px-1 pb-1 pt-16">
      <span class="block overflow-hidden rounded-2xl">
        <OnImg class="aspect-[4/3] h-auto w-full" :src="props.image.src" :alt="props.image.alt" sizes="sm:300px md:400px" />
      </span>
    </span>
    <span class="flex flex-col items-center bg-gradient-to-b from-background px-0.5 pb-4 pt-3 text-center">
      <span class="text-subheading font-medium">
        {{ props.title }}
        <span v-if="props.discountPercent" class="text-text-attention">
          <DiscountPercentage :discount-percentage="props.discountPercent" />
        </span>
      </span>
      <span class="mt-3 text-lg font-medium">
        <template v-if="props.discountPrice">
          <Price :amount="props.discountPrice" />
          <span class="ml-2 font-normal text-text-disabled line-through">
            <Price :amount="props.price" large-fractional />
          </span>
        </template>
        <template v-else>
          <Price :amount="props.price" />
        </template>
      </span>
      <span class="mt-6 inline-block rounded-3xl border border-border px-4 py-3 text-button-m font-medium group-hover:border-border-hover">{{ t('w.module.premium.button') }}</span>
    </span>
  </NuxtLink>
</template>

<script setup lang="ts">
import { useTranslate } from '~/composables/translate'

import OnImg from '~/components/on-img/OnImg.vue'
import DiscountPercentage from '~/components/price/DiscountPercentage.vue'

const props = withDefaults(
  defineProps<{
    url: string
    image: {
      src: string
      alt: string
    }
    title: string
    price: number
    discountPercent?: number | undefined
    discountPrice?: number | undefined | null
  }>(),
  {
    discountPercent: undefined,
    discountPrice: undefined,
  }
)

const { t } = useTranslate()
</script>
