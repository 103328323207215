<template>
  <div class="h-container max-w-[1650px]">
    <div class="pb-10 pt-4 text-center md:pb-11 xl:pb-12">
      <h2 v-if="t('w.module.blog_grid.title')">{{ t('w.module.blog_grid.title') }}</h2>
      <Button v-if="t('w.module.blog_grid.action.url')" :link="t('w.module.blog_grid.action.url')" class="btn-outline btn-rounded mt-8">
        {{ t('w.module.blog_grid.action.text') }}
      </Button>
    </div>
    <div class="h-gap grid grid-cols-1 xs:grid-cols-2 md:grid-cols-6">
      <NuxtLink
        v-for="(item, itemIndex) in props.data"
        :key="`blog-card-${itemIndex}`"
        :to="item.url"
        class="h-translate-y-0 hover:h-translate-y-1 group relative block transition-transform duration-500"
        :class="[itemIndex === 0 || itemIndex === 1 ? 'md:col-span-3' : 'md:col-span-2']"
      >
        <div class="aspect-2/1">
          <OnImg
            :src="item.image.src"
            :alt="item.image.alt"
            sizes="sm:600px lg:900px"
            class="size-full rounded-2xl object-cover transition-all duration-500 group-hover:shadow-05"
          />
        </div>
        <div class="subtitle mb-5 mt-6 leading-5 group-hover:text-text-strong sm:mb-4 md:mb-5 xl:mb-4">
          {{ item.title }}
          <Icon name="arrow-right" class="size-5 align-bottom" />
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import OnImg from '~/components/on-img/OnImg.vue'
import Icon from '~/components/icon/Icon.vue'

const { t } = useTranslate()

interface BlogGridItem {
  title: string
  url: string
  image: {
    src: string
    alt: string
  }
}

const props = defineProps<{
  data: BlogGridItem[]
}>()
</script>
